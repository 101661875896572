<template>
  <div :class="['Table', { 'Table--mobile': mobile.isMobile }]">
    <!-- If you want a heading and subheading in the top of the table -->
    <!-- Content passed here should have class: Table__slot-content -->
    <slot />

    <div class="Table__head-wrapper row row--justify-between">
      <label class="Table__header-lbl Table__header-lbl--BaseClientTransferTable">Type</label>
      <label class="Table__header-lbl Table__header-lbl--BaseClientTransferTable">Sent</label>
      <label class="Table__header-lbl Table__header-lbl--BaseClientTransferTable">Arrival</label>
      <label class="Table__header-lbl Table__header-lbl--BaseClientTransferTable">+/-</label>
      <label class="Table__header-lbl Table__header-lbl--BaseClientTransferTable">Fees</label>
      <label class="Table__header-lbl Table__header-lbl--BaseClientTransferTable">Funded</label>
      <!-- Fake/hidden column for the PDF icon/button -->
      <label class="Table__header-lbl Table__header-lbl--BaseClientTransferTable" />
    </div>

    <div
      v-for="(transfer, index) in transfers"
      :key="index"
      @click="expandTransfer(transfer)"
      class="Table__item--BaseClientTransferTable clickable"
    >
      <div
        :class="['Table__item-wrapper Table__item-wrapper--BaseClientTransferTable row', {
          'Table__item-wrapper--expanded': transfer.expanded
        }]"
        :data-cy="`base-transfers-table-item-${index}`"
      >
        <!-- TRANSFER METHOD -->
        <div class="column">
          <label class="fs-15 fc-light uppercase">
            {{ transfer.batch_payment.transfer_type }}
          </label>
        </div>

        <!-- SENT -->
        <div class="column">
          <time
            class="Table__time-lbl Table__time-lbl--BaseClientTransferTable fs-15"
            :datetime="transfer.batch_payment.created_at | datetime('YYYY-MM-DD hh:mm:ssZ')"
          >
            {{ transfer.batch_payment.created_at | datetime('h:mma') }}
          </time>
          <time
            class="Table__date-lbl Table__date-lbl--BaseClientTransferTable fc-light fs-13"
            :datetime="transfer.batch_payment.created_at | datetime('YYYY-MM-DD hh:mm:ssZ')"
          >
            {{ transfer.batch_payment.created_at | datetime('M/D/YY') }}
          </time>
        </div>

        <!-- ARRIVAL -->
        <div class="column">
          <time
            class="Table__time-lbl Table__time-lbl--BaseClientTransferTable fs-15"
            :datetime="transfer.batch_payment.expected_payment_date | datetime('YYYY-MM-DD hh:mm:ssZ')"
          >
            {{ transfer.batch_payment.expected_payment_date | datetime('h:mma') }}
          </time>
          <time
            class="Table__date-lbl Table__date-lbl--BaseClientTransferTable fc-light fs-13"
            :datetime="transfer.batch_payment.expected_payment_date | datetime('YYYY-MM-DD hh:mm:ssZ')"
          >
            {{ transfer.batch_payment.expected_payment_date | datetime('M/D/YY') }}
          </time>
        </div>

        <!-- +/- -->
        <div class="column">
          <label
            :class="['fs-14', {
              'fc-dark': transfer.approved_invoice_adjustment_amount > 0,
              'fc-red': transfer.approved_invoice_adjustment_amount < 0,
              'fc-light': transfer.approved_invoice_adjustment_amount === 0
            }]"
            data-cy="base-transfers-table-change-amount"
          >
            {{
              Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                .format(
                  (transfer.approved_invoice_adjustment_amount - Math.abs(transfer.fee)) / 100
                )
            }}
          </label>
        </div>

        <!-- Factor Fee -->
        <div class="column">
          <label class="fs-15 fc-light">
            <!-- Needs to be the dollar value of the aggregate of the invoices' approved_factor_fee
            for this batch -->
            {{
              Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                .format(
                  transfer.invoices.reduce((acc, cur) => acc + cur.approved_factor_fee, 0) / 100
                )
            }}
          </label>
        </div>

        <!-- Funded -->
        <div class="column">
          <label class="fs-15 fc-light">
            {{
              Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                .format((transfer.amount / 100))
            }}
          </label>
        </div>

        <!-- PDF Report Button -->
        <div class="column">
          <base-button
            v-if="isBeforeToday(transfer.created_at)"
            @click.stop="createDailyReport(transfer)"
            class="bg-trans"
          >
            <i class="BaseClientTransferTable__report-icon fa fa-invoice fa--none" />
          </base-button>
        </div>
      </div>

      <transition name="Transition__fade">
        <div
          v-if="transfer.expanded"
          class="Table__item-expanded-wrapper--BaseClientTransferTable bg column"
        >
          <div class="Table__mobile-expanded-heading column">
            <label class="fs-16 fc-blue">Invoice Details</label>
            <label class="fs-14 fc-light">These invoices were included in the transfer</label>
          </div>
          <div class="Table__item-expanded-wrapper width-100">
            <!-- Desktop ONLY  -->
            <div class="Table__head-wrapper--expanded row">
              <label class="Table__header-lbl Table__header-lbl--BaseClientTransferTable">
                Debtor
              </label>
              <label class="Table__header-lbl Table__header-lbl--BaseClientTransferTable">
                {{
                  mobile.isMobile
                    ? 'Load #'
                    : 'Load Number'
                }}
              </label>
              <label class="Table__header-lbl Table__header-lbl--BaseClientTransferTable">
                Memo
              </label>
              <label class="Table__header-lbl Table__header-lbl--BaseClientTransferTable">
                +/-
              </label>
              <label class="Table__header-lbl Table__header-lbl--BaseClientTransferTable">
                Amount
              </label>
            </div>

            <!-- TRANSFER'S INVOICE HISTORY -->
            <div
              v-for="(invoice, invoiceIndex) in transfer.invoices"
              :key="invoiceIndex"
              class="Table__item-wrapper Table__item-wrapper--BaseClientTransferTable row"
            >
              <!-- DEBTOR -->
              <div class="column">
                <label class="BaseClientTransferTable__debtor-name fc-mid fs-14 uppercase">
                  {{ sanitizeAlgoliaNames(invoice.debtor_selected_name) }}
                </label>
              </div>

              <!-- LOAD # -->
              <div class="column">
                <label
                  @click.stop="goToPaidInvoices(invoice)"
                  class="fc-mid fs-14 uppercase"
                >
                  {{
                    invoice.load_number.length > 10
                      ? `${invoice.load_number.substring(0, 7)}...`
                      : invoice.load_number
                  }}
                </label>
              </div>

              <!-- MEMO -->
              <div class="column">
                <label
                  class="fc-mid fs-14"
                  :data-cy="`base-transfers-table-item-memo-${invoice.load_number}`"
                >
                  {{ invoice.memo }}
                </label>
              </div>

              <!-- +/- -->
              <div class="column">
                <label
                  :class="['fs-14 uppercase', {
                    'fc-dark': invoice.approved_invoice_adjustment_amount > 0,
                    'fc-red': invoice.approved_invoice_adjustment_amount < 0,
                    'fc-light': invoice.approved_invoice_adjustment_amount === 0
                  }]"
                >
                  {{
                    Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                      .format(invoice.approved_invoice_adjustment_amount / 100)
                  }}
                </label>
              </div>

              <!-- AMOUNT -->
              <div class="column">
                <label class="fc-light fs-14">
                  {{
                    Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                      .format((invoice.client_total_to_pay_from_invoice / 100))
                  }}
                </label>
              </div>
            </div>

            <!-- TRANSFER'S BALANCE HISTORY -->
            <div
              v-for="(balance, balanceIndex) in transfer.balances"
              :key="balanceIndex"
              class="
                Table__item-wrapper Table__item-wrapper--BaseClientTransferTable
                row row--align-center
              "
            >
              <!-- Release of Funds -->
              <div class="column">
                <label class="BaseClientTransferTable__debtor-name fc-mid fs-14 capitalize">
                  Release of Funds
                </label>
              </div>

              <!-- LOAD # -->
              <div class="column" />

              <!-- +/- -->
              <div class="column" />

              <!-- AMOUNT -->
              <div class="column">
                <label
                  :class="['fs-14 uppercase', {
                    'fc-dark': balance.amount > 0,
                    'fc-red': balance.amount < 0,
                    'fc-light': balance.amount === 0
                  }]"
                >
                  {{
                    Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                      .format(balance.amount / 100)
                  }}
                </label>
              </div>
            </div>

            <!-- WIRE FEE ITEM (IF TRANSFER TYPE WAS A WIRE) -->
            <!-- Represents the $15 increase in the tranfer's +/- (at top) if a wire-->
            <!-- https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1687 -->
            <div
              v-if="transfer.fee"
              class="Table__item-wrapper Table__item-wrapper--BaseClientTransferTable row"
            >
              <!-- WIRE FEE -->
              <div class="column">
                <label class="BaseClientTransferTable__debtor-name fc-mid fs-14 capitalize">
                  Wire Fee
                </label>
              </div>

              <!-- LOAD # -->
              <div class="column" />

              <!-- MEMO -->
              <div class="column" />

              <!-- +/- -->
              <div class="column">
                <label class="fc-red fs-14 uppercase">
                  -{{
                    Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                      .format(transfer.fee / 100)
                  }}
                </label>
              </div>

              <!-- AMOUNT -->
              <div class="column" />
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
// Packages
import moment from 'moment'
import { Cron } from '../utils/api'
import { sanitizeAlgoliaNames } from '../utils/helpers'
// Components
import BaseButton from './base-button.vue'

export default {
  name: 'BaseClientTransferTable',

  components: {
    BaseButton,
  },

  props: {
    transfers: {
      type: Array,
      required: true,
    },
  },

  computed: {
    mobile () {
      return this.$store.getters.mobile
    },
  },

  methods: {
    async createDailyReport (transfer) {
      this.progressStart()
      try {
        const clientId = transfer.client_id
        const date = moment(transfer.created_at).tz('America/New_York').endOf('day').toDate()
        const file = (await Cron.runDailyReport({
          clientId,
          date,
          deliveryMethod: 'download'
        })).data.url
        window.open(file, '_blank')
        this.progressFinish()
      } catch (error) {
        this.captureSentryEvent(
          `${this.adjustmentFilter} Daily Report Generation Error`,
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue generating the daily report' })
      }
    },

    expandTransfer (transfer) {
      if (transfer.expanded) {
        this.$set(transfer, 'expanded', false)
        return
      }
      this.$set(transfer, 'expanded', true)
    },

    goToPaidInvoices (invoice) {
      if (this.mobile.isMobile) return
      this.CLog(invoice)
      // Go to paid invoices page w/ invoice's load number
      const route = this.$router.resolve({
        path: 'invoices/search-results',
        query: {
          id: invoice.id,
          loadNumber: invoice.load_number,
          offset: 1
        },
      }, '/invoices/paid', false)
      this.CLog(route)
      window.open(route.href, '_blank')
    },

    isBeforeToday (date) {
      const startOfDay = moment.tz(date, 'America/New_York').startOf('day')
      const startOfToday = moment.tz('America/New_York').endOf('day')
      return startOfDay.diff(startOfToday, 'days') < 0
    },

    moment,

    sanitizeAlgoliaNames,
  },

}
</script>

<style lang="sass">
@import '../styles/tables'

.BaseClientTransferTable

  &__debtor-name
    +overflow-ellipsis(2, $hyphens: true)

  &__report-icon
    margin-left: rem(-10px)
    margin-right: rem(-10px)
    padding: rem(8px) rem(10px) rem(8px) rem(10px)
</style>