var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["Table", { "Table--mobile": _vm.mobile.isMobile }] },
    [
      _vm._t("default"),
      _vm._m(0),
      _vm._l(_vm.transfers, function(transfer, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "Table__item--BaseClientTransferTable clickable",
            on: {
              click: function($event) {
                return _vm.expandTransfer(transfer)
              }
            }
          },
          [
            _c(
              "div",
              {
                class: [
                  "Table__item-wrapper Table__item-wrapper--BaseClientTransferTable row",
                  {
                    "Table__item-wrapper--expanded": transfer.expanded
                  }
                ],
                attrs: { "data-cy": "base-transfers-table-item-" + index }
              },
              [
                _c("div", { staticClass: "column" }, [
                  _c("label", { staticClass: "fs-15 fc-light uppercase" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(transfer.batch_payment.transfer_type) +
                        "\n        "
                    )
                  ])
                ]),
                _c("div", { staticClass: "column" }, [
                  _c(
                    "time",
                    {
                      staticClass:
                        "Table__time-lbl Table__time-lbl--BaseClientTransferTable fs-15",
                      attrs: {
                        datetime: _vm._f("datetime")(
                          transfer.batch_payment.created_at,
                          "YYYY-MM-DD hh:mm:ssZ"
                        )
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm._f("datetime")(
                              transfer.batch_payment.created_at,
                              "h:mma"
                            )
                          ) +
                          "\n        "
                      )
                    ]
                  ),
                  _c(
                    "time",
                    {
                      staticClass:
                        "Table__date-lbl Table__date-lbl--BaseClientTransferTable fc-light fs-13",
                      attrs: {
                        datetime: _vm._f("datetime")(
                          transfer.batch_payment.created_at,
                          "YYYY-MM-DD hh:mm:ssZ"
                        )
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm._f("datetime")(
                              transfer.batch_payment.created_at,
                              "M/D/YY"
                            )
                          ) +
                          "\n        "
                      )
                    ]
                  )
                ]),
                _c("div", { staticClass: "column" }, [
                  _c(
                    "time",
                    {
                      staticClass:
                        "Table__time-lbl Table__time-lbl--BaseClientTransferTable fs-15",
                      attrs: {
                        datetime: _vm._f("datetime")(
                          transfer.batch_payment.expected_payment_date,
                          "YYYY-MM-DD hh:mm:ssZ"
                        )
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm._f("datetime")(
                              transfer.batch_payment.expected_payment_date,
                              "h:mma"
                            )
                          ) +
                          "\n        "
                      )
                    ]
                  ),
                  _c(
                    "time",
                    {
                      staticClass:
                        "Table__date-lbl Table__date-lbl--BaseClientTransferTable fc-light fs-13",
                      attrs: {
                        datetime: _vm._f("datetime")(
                          transfer.batch_payment.expected_payment_date,
                          "YYYY-MM-DD hh:mm:ssZ"
                        )
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm._f("datetime")(
                              transfer.batch_payment.expected_payment_date,
                              "M/D/YY"
                            )
                          ) +
                          "\n        "
                      )
                    ]
                  )
                ]),
                _c("div", { staticClass: "column" }, [
                  _c(
                    "label",
                    {
                      class: [
                        "fs-14",
                        {
                          "fc-dark":
                            transfer.approved_invoice_adjustment_amount > 0,
                          "fc-red":
                            transfer.approved_invoice_adjustment_amount < 0,
                          "fc-light":
                            transfer.approved_invoice_adjustment_amount === 0
                        }
                      ],
                      attrs: { "data-cy": "base-transfers-table-change-amount" }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD"
                            }).format(
                              (transfer.approved_invoice_adjustment_amount -
                                Math.abs(transfer.fee)) /
                                100
                            )
                          ) +
                          "\n        "
                      )
                    ]
                  )
                ]),
                _c("div", { staticClass: "column" }, [
                  _c("label", { staticClass: "fs-15 fc-light" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD"
                          }).format(
                            transfer.invoices.reduce(function(acc, cur) {
                              return acc + cur.approved_factor_fee
                            }, 0) / 100
                          )
                        ) +
                        "\n        "
                    )
                  ])
                ]),
                _c("div", { staticClass: "column" }, [
                  _c("label", { staticClass: "fs-15 fc-light" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD"
                          }).format(transfer.amount / 100)
                        ) +
                        "\n        "
                    )
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "column" },
                  [
                    _vm.isBeforeToday(transfer.created_at)
                      ? _c(
                          "base-button",
                          {
                            staticClass: "bg-trans",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.createDailyReport(transfer)
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass:
                                "BaseClientTransferTable__report-icon fa fa-invoice fa--none"
                            })
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]
            ),
            _c("transition", { attrs: { name: "Transition__fade" } }, [
              transfer.expanded
                ? _c(
                    "div",
                    {
                      staticClass:
                        "Table__item-expanded-wrapper--BaseClientTransferTable bg column"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "Table__mobile-expanded-heading column"
                        },
                        [
                          _c("label", { staticClass: "fs-16 fc-blue" }, [
                            _vm._v("Invoice Details")
                          ]),
                          _c("label", { staticClass: "fs-14 fc-light" }, [
                            _vm._v(
                              "These invoices were included in the transfer"
                            )
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "Table__item-expanded-wrapper width-100"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "Table__head-wrapper--expanded row"
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "Table__header-lbl Table__header-lbl--BaseClientTransferTable"
                                },
                                [_vm._v("\n              Debtor\n            ")]
                              ),
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "Table__header-lbl Table__header-lbl--BaseClientTransferTable"
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.mobile.isMobile
                                          ? "Load #"
                                          : "Load Number"
                                      ) +
                                      "\n            "
                                  )
                                ]
                              ),
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "Table__header-lbl Table__header-lbl--BaseClientTransferTable"
                                },
                                [_vm._v("\n              Memo\n            ")]
                              ),
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "Table__header-lbl Table__header-lbl--BaseClientTransferTable"
                                },
                                [_vm._v("\n              +/-\n            ")]
                              ),
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "Table__header-lbl Table__header-lbl--BaseClientTransferTable"
                                },
                                [_vm._v("\n              Amount\n            ")]
                              )
                            ]
                          ),
                          _vm._l(transfer.invoices, function(
                            invoice,
                            invoiceIndex
                          ) {
                            return _c(
                              "div",
                              {
                                key: invoiceIndex,
                                staticClass:
                                  "Table__item-wrapper Table__item-wrapper--BaseClientTransferTable row"
                              },
                              [
                                _c("div", { staticClass: "column" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "BaseClientTransferTable__debtor-name fc-mid fs-14 uppercase"
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.sanitizeAlgoliaNames(
                                              invoice.debtor_selected_name
                                            )
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ]),
                                _c("div", { staticClass: "column" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "fc-mid fs-14 uppercase",
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.goToPaidInvoices(invoice)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            invoice.load_number.length > 10
                                              ? invoice.load_number.substring(
                                                  0,
                                                  7
                                                ) + "..."
                                              : invoice.load_number
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ]),
                                _c("div", { staticClass: "column" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "fc-mid fs-14",
                                      attrs: {
                                        "data-cy":
                                          "base-transfers-table-item-memo-" +
                                          invoice.load_number
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(invoice.memo) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ]),
                                _c("div", { staticClass: "column" }, [
                                  _c(
                                    "label",
                                    {
                                      class: [
                                        "fs-14 uppercase",
                                        {
                                          "fc-dark":
                                            invoice.approved_invoice_adjustment_amount >
                                            0,
                                          "fc-red":
                                            invoice.approved_invoice_adjustment_amount <
                                            0,
                                          "fc-light":
                                            invoice.approved_invoice_adjustment_amount ===
                                            0
                                        }
                                      ]
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            Intl.NumberFormat("en-US", {
                                              style: "currency",
                                              currency: "USD"
                                            }).format(
                                              invoice.approved_invoice_adjustment_amount /
                                                100
                                            )
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ]),
                                _c("div", { staticClass: "column" }, [
                                  _c(
                                    "label",
                                    { staticClass: "fc-light fs-14" },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            Intl.NumberFormat("en-US", {
                                              style: "currency",
                                              currency: "USD"
                                            }).format(
                                              invoice.client_total_to_pay_from_invoice /
                                                100
                                            )
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ])
                              ]
                            )
                          }),
                          _vm._l(transfer.balances, function(
                            balance,
                            balanceIndex
                          ) {
                            return _c(
                              "div",
                              {
                                key: balanceIndex,
                                staticClass:
                                  "\n              Table__item-wrapper Table__item-wrapper--BaseClientTransferTable\n              row row--align-center\n            "
                              },
                              [
                                _c("div", { staticClass: "column" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "BaseClientTransferTable__debtor-name fc-mid fs-14 capitalize"
                                    },
                                    [
                                      _vm._v(
                                        "\n                Release of Funds\n              "
                                      )
                                    ]
                                  )
                                ]),
                                _c("div", { staticClass: "column" }),
                                _c("div", { staticClass: "column" }),
                                _c("div", { staticClass: "column" }, [
                                  _c(
                                    "label",
                                    {
                                      class: [
                                        "fs-14 uppercase",
                                        {
                                          "fc-dark": balance.amount > 0,
                                          "fc-red": balance.amount < 0,
                                          "fc-light": balance.amount === 0
                                        }
                                      ]
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            Intl.NumberFormat("en-US", {
                                              style: "currency",
                                              currency: "USD"
                                            }).format(balance.amount / 100)
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ])
                              ]
                            )
                          }),
                          transfer.fee
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "Table__item-wrapper Table__item-wrapper--BaseClientTransferTable row"
                                },
                                [
                                  _c("div", { staticClass: "column" }, [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "BaseClientTransferTable__debtor-name fc-mid fs-14 capitalize"
                                      },
                                      [
                                        _vm._v(
                                          "\n                Wire Fee\n              "
                                        )
                                      ]
                                    )
                                  ]),
                                  _c("div", { staticClass: "column" }),
                                  _c("div", { staticClass: "column" }),
                                  _c("div", { staticClass: "column" }, [
                                    _c(
                                      "label",
                                      { staticClass: "fc-red fs-14 uppercase" },
                                      [
                                        _vm._v(
                                          "\n                -" +
                                            _vm._s(
                                              Intl.NumberFormat("en-US", {
                                                style: "currency",
                                                currency: "USD"
                                              }).format(transfer.fee / 100)
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  ]),
                                  _c("div", { staticClass: "column" })
                                ]
                              )
                            : _vm._e()
                        ],
                        2
                      )
                    ]
                  )
                : _vm._e()
            ])
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "Table__head-wrapper row row--justify-between" },
      [
        _c(
          "label",
          {
            staticClass:
              "Table__header-lbl Table__header-lbl--BaseClientTransferTable"
          },
          [_vm._v("Type")]
        ),
        _c(
          "label",
          {
            staticClass:
              "Table__header-lbl Table__header-lbl--BaseClientTransferTable"
          },
          [_vm._v("Sent")]
        ),
        _c(
          "label",
          {
            staticClass:
              "Table__header-lbl Table__header-lbl--BaseClientTransferTable"
          },
          [_vm._v("Arrival")]
        ),
        _c(
          "label",
          {
            staticClass:
              "Table__header-lbl Table__header-lbl--BaseClientTransferTable"
          },
          [_vm._v("+/-")]
        ),
        _c(
          "label",
          {
            staticClass:
              "Table__header-lbl Table__header-lbl--BaseClientTransferTable"
          },
          [_vm._v("Fees")]
        ),
        _c(
          "label",
          {
            staticClass:
              "Table__header-lbl Table__header-lbl--BaseClientTransferTable"
          },
          [_vm._v("Funded")]
        ),
        _c("label", {
          staticClass:
            "Table__header-lbl Table__header-lbl--BaseClientTransferTable"
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }